import React, { useEffect } from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useHistory } from "react-router";
import { db } from "firebase/firebase.utils";
import { app } from "firebase/firebase.utils";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Switch from "react-bootstrap-switch";
import { Alert } from "reactstrap";
import { functions } from "firebase/firebase.utils";
import { getFunctions, httpsCallable, setData } from "firebase/functions";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
} from "reactstrap";

// core components
import DefaultFooter from "components/Footers/DefaultFooter.js";
import VenueNavbar from "components/Navbars/VenueNavbar";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import LoadingSpinner from "components/subcomponent/LoadingSpinner";

function OnBoardCompletionPage() {
  const [pills, setPills] = React.useState("1");
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [venue, setVenue] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [venueName, setVenueName] = React.useState("");
  const [venueAddress, setVenueAddress] = React.useState("");
  const [venueCity, setVenueCity] = React.useState("");
  const [venueZip, setVenueZip] = React.useState("");
  const [web, setWeb] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [venueID, setVenueID] = React.useState("");
  const [testPass, setTestPass] = React.useState(20);
  const [passPrice, setPassPrice] = React.useState(0);
  const [reservePass, setReservePass] = React.useState(20);
  const [automation, setAutomation] = React.useState(true);
  const [alert1, setAlert1] = React.useState(false);
  const [alertType, setAlertType] = React.useState("success");
  const [alertMessage, setAlertMessage] = React.useState("Alert!");
  const [dataValid, setDataValid] = React.useState(false);
  const [phoneValid, setPhoneValid] = React.useState(true);
  const [zipValid, setZipValid] = React.useState(true);
  const [urlValid, setUrlValid] = React.useState(true);
  const [stripeURL, setStripeURL] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = getAuth();
  let history = useHistory();

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUser(user);
        //console.log(uid)
        var docRef = db.collection("users").doc(uid);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              //console.log(doc.data())
              const onBoard = doc.data().onBoard;
              const M = doc.data().M;

              if (onBoard == false && M != "") {
                history.push("/venue");
              } else if (onBoard == true && M == "") {
                history.push("/onboard");
              } else if (M != "" && onBoard == true) {
                //Right Place

                db.collection("venues")
                  .doc(M)
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      let venData = doc.data();
                      setVenue(venData);
                      setVenueName(venData.venueName);
                      const functions = getFunctions();
                      const data = { account: venData.stripeConnectID };

                      const checkAccount = httpsCallable(
                        functions,
                        "checkConnectAccount"
                      );

                      checkAccount(data).then((result) => {
                        const data = result.data;
                        //console.log(data)
                        if (data.complete) {
                          setPills("2");
                          //console.log(pills)
                        } else {
                          setPills("3");
                          //stripeURL = data.url
                          setStripeURL(data.url); //window.location.href = data.url;
                        }
                      });
                    } else {
                    }
                  });
              }
            } else {
              signOut(auth);
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });

        //history.push("/user")
        // ...
      } else {
        // User is signed out
        // ...
      }
    });

    return unsubscribe;
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
  };
  const finishOnBoard = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    db.collection("users").doc(user.uid).update(
      {
        onBoard: false,
      },
      { merge: true }
    );
    history.push("/venue");
  };

  const returnToStripe = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    window.location.href = stripeURL;
  };

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    //populateForm();

    console.log("starting...");

    //handleVenAddressChange();
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      {isLoading ? <LoadingSpinner /> : <center></center>}
      <VenueNavbar />
      <div className="wrapper">
        <ProfilePageHeader />

        <div className="section">
          <Container>
            <Row style={{ display: "block" }}>
              <Col className="ml-auto mr-auto" md="6">
                <div className="nav-align-center"></div>
              </Col>
              <TabContent className="gallery" activeTab={"pills" + pills}>
                <TabPane tabId="pills1">
                  <Col className="ml-auto mr-auto" md="10">
                    <center>
                      <img
                        alt="..."
                        //className="img-raised"
                        style={{ maxHeight: "50px" }}
                        src={require("assets/img/spin.gif").default}
                      ></img>
                      <h2>Checking OnBoarding status</h2>
                    </center>
                  </Col>
                </TabPane>
                <TabPane tabId="pills2">
                  <Col className="ml-auto mr-auto" md="10">
                    <Card className="card-plain">
                      <center>
                        <h2 stlye={{ fontWeight: "bold" }}>Congrats!</h2>
                        <p>
                          {venueName}'s stripe account is fully activated. Click
                          Below to complete the on-boarding process.
                        </p>
                        <Button onClick={finishOnBoard} disabled={isLoading}>
                          Complete
                        </Button>
                      </center>
                    </Card>
                  </Col>
                </TabPane>
                <TabPane tabId="pills3">
                  <Col className="ml-auto mr-auto" md="10">
                    <Card className="card-plain">
                      <center>
                        <h2 stlye={{ fontWeight: "bold" }}>Almost There!</h2>
                        <p>
                          {venueName}'s stripe account has not been fully
                          activated. Click Below to return to Stripe to complete
                          the on-boarding process.
                        </p>
                        <Button onClick={returnToStripe} disabled={isLoading}>
                          Return to Stripe
                        </Button>
                        <p>
                          It may take a few minutes for Stripe to process your
                          account. <br />
                          If you've completed all the required steps, try again
                          momentarily.{" "}
                        </p>
                      </center>
                    </Card>
                  </Col>
                </TabPane>
              </TabContent>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default OnBoardCompletionPage;
