import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { db } from "firebase/firebase.utils";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { DataThresholding } from "@mui/icons-material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

let usDollar = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const auth = getAuth();

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

//name, date, amount, qty, passID, status
function createData(
  name,
  date,
  price,
  qty,
  passID,
  status,
  customerId,
  redemptionDate,
  redemptionMethod,
  sortDate
) {
  return {
    name,
    date,
    price,
    qty,
    sortDate,
    passID,
    status,
    purchase: [
      {
        date: date,
        customerId: customerId,
        amount: qty,
      },
    ],
    redemption: [
      {
        date: redemptionDate,
        method: redemptionMethod,
      },
    ],
    profit: [
      {
        rate: "15% + $0.50",
        grossProfit: price * qty,
        lyztFee: price * qty * 0.15 + 50,
        netProfit: price * qty - (price * qty * 0.15 + 50),
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ background: "lightgray" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {row.name}
        </TableCell>
        <TableCell align="left">{row.passID}</TableCell>
        <TableCell align="center">{row.date}</TableCell>
        <TableCell align="center">{row.status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                size="small"
                aria-label="purchases"
                style={{ width: "80%" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Pass Price</TableCell>
                    <TableCell align="center">Quanity</TableCell>
                    <TableCell align="center">Gross Profit</TableCell>
                    <TableCell align="center">Lyzt Fee</TableCell>
                    <TableCell align="center">Net Profit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.profit.map((profitRow) => (
                    <TableRow key={profitRow.lyztFee}>
                      <TableCell component="th" scope="row" align="center">
                        {usDollar.format(row.price / 100)}
                      </TableCell>
                      <TableCell align="center">{row.qty}</TableCell>
                      <TableCell align="center">
                        {usDollar.format(profitRow.grossProfit / 100)}
                      </TableCell>
                      <TableCell align="center">
                        {usDollar.format(profitRow.lyztFee / 100)}
                      </TableCell>
                      <TableCell align="center">
                        {usDollar.format(profitRow.netProfit / 100)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
//name, calories, fat, carbs, protein, price
//name, date, price, qty, passID, status, customerId, redemptionDate, redemptionMethod

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    date: PropTypes.any.isRequired,
    price: PropTypes.number.isRequired,
    qty: PropTypes.number.isRequired,
    passID: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    purchase: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.any.isRequired,
        customerId: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
      })
    ).isRequired,
    redemption: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.any,
        method: PropTypes.string,
      })
    ).isRequired,
    profit: PropTypes.arrayOf(
      PropTypes.shape({
        rate: PropTypes.string.isRequired,
        grossProfit: PropTypes.number.isRequired,
        lyztFee: PropTypes.number.isRequired,
        netProfit: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

//       rate: "15% + $0.50",
//       grossProfit: (price * qty),
//       lyztFee: (price * qty * 0.15) + 50 ,
//       netProfit: (price * qty) - ((price * qty * 0.15) + 50),
//
//     },
//   ],

const rows = [
  //createData('Nandor Kiss', 'Today', 5500, 4, "asdfas;dfjaf;", "N", "adsfasdf", "today", "scanned")
  //createData(name, date, price, qty, passID, status, customerId, redemptionDate, redemptionMethod)
].sort((a, b) => a.sortDate < b.sortDate);

export default function CollapsibleTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        var docRef = db.collection("users").doc(uid);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              const M = doc.data().M;
              db.collection("venues")
                .doc(M)
                .collection("passes")
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    var data = doc.data();
                    var stylePrice = "$" + data.passPrice / 100;
                    if (!data.passQTY) {
                      data.passQTY = 1;
                    }

                    var fulldate = new Timestamp(
                      data.passDate.seconds,
                      data.passDate.nanoseconds
                    ).toDate();
                    //fulldate.getMonth
                    const months = [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December",
                    ];
                    let month = months[fulldate.getMonth()];
                    var finalDate =
                      month +
                      " " +
                      fulldate.getDate() +
                      ", " +
                      fulldate.getFullYear();

                    const newentry = createData(
                      data.userName,
                      finalDate,
                      data.passPrice,
                      data.passQTY,
                      data.passID,
                      data.Active,
                      data.userDocID,
                      data.redemptionDate,
                      data.redemptionMethod,
                      fulldate
                    );
                    rows.push(newentry);

                    rows.sort((a, b) => b.sortDate - a.sortDate);
                  });
                });
            } else {
              signOut(auth);
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
      }
    });
    return unsubscribe;
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow style={{ backgroundColor: "#454545" }}>
            <TableCell />
            <TableCell
              align="left"
              style={{ color: "white", fontWeight: "bold" }}
            >
              Customer
            </TableCell>
            <TableCell
              align="left"
              style={{ color: "white", fontWeight: "bold" }}
            >
              Pass ID
            </TableCell>
            <TableCell
              align="center"
              style={{ color: "white", fontWeight: "bold" }}
            >
              Date
            </TableCell>
            <TableCell
              align="center"
              style={{ color: "white", fontWeight: "bold" }}
            >
              Active
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <Row key={row.passID} row={row} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow style={{ background: "lightgray" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={5}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
