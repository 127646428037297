import React from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useHistory } from "react-router";
import { db } from "firebase/firebase.utils";
import { getFunctions, httpsCallable, setData } from "firebase/functions";
import { Alert } from "reactstrap";
import LoadingSpinner from "components/subcomponent/LoadingSpinner";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import VenueNavbar from "components/Navbars/VenueNavbar";
import { validateNamespace } from "@firebase/util";

function OnBoardPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [user, setUser] = React.useState("");
  const [appUser, setAppUser] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState(
    "You must provide the required information!"
  );
  const [venueName, setVenueName] = React.useState("");
  const [venueAddress, setVenueAddress] = React.useState("");
  const [venueCity, setVenueCity] = React.useState("");
  const [venueZip, setVenueZip] = React.useState("");
  const [web, setWeb] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [venState, setVenState] = React.useState("");
  const [venueId, setVenueId] = React.useState("");

  const [alert1, setAlert1] = React.useState(false);
  const [alertType, setAlertType] = React.useState("danger");
  const [alertMessage, setAlertMessage] = React.useState(errorMessage);
  const [isLoading, setIsLoading] = React.useState(false);

  var venueIdForStripe = "";
  const auth = getAuth();
  let history = useHistory();
  var dataValid = false;
  var zipValid = false;
  var urlValid = false;
  var phoneValid = false;

  onAuthStateChanged(auth, (user) => {
    if (!user) {
      history.push("/login-page");
    } else {
      setUser(user);
    }
  });

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        //console.log(uid);
        var docRef = db.collection("users").doc(uid);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              //console.log(doc.data());
              const onBoard = doc.data().onBoard;
              const M = doc.data().M;

              if (onBoard == false && M != "") {
                history.push("/venue");
              } else if (M != "" && onBoard == true) {
                history.push("/onboardcompletion");
              }
            } else {
              signOut(auth);
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });

        //history.push("/user")
        // ...
      } else {
        // User is signed out
        // ...
      }
    });

    return unsubscribe;
  }, []);

  const stripeConnectRedirect = (event) => {
    setIsLoading(true);
    const functions = getFunctions();
    const data = {
      email: user.email,
      phone: phone,
      venueCity: venueCity,
      venueAddress: venueAddress,
      venueZip: venueZip,
      venueId: venueIdForStripe,
      venueState: venState,
    };

    const returnMessage = httpsCallable(functions, "newConnectAccount");

    returnMessage(data).then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      const data = result.data;
      const stripeConnectID = data.accountID;
      // db.collection('venues').doc("testVenClient").update({
      //   stripeConnectID: stripeConnectID,
      //   }, { merge: true })
      //console.log(data)
      window.location.href = data.url;
    });
  };

  const handleVenNameChange = (event) => {
    setAlert1(false);
    setVenueName(event.target.value);
    if ((event.target.value = "")) {
      setErrorMessage("Venue name cannot be blank");
    }
  };
  const handleVenAddressChange = (event) => {
    setVenueAddress(event.target.value);
  };
  const handleVenCityChange = (event) => {
    setVenueCity(event.target.value);
  };
  const handleVenZipChange = (event) => {
    setVenueZip(event.target.value);
    const state = getState(event.target.value);
    setVenState(state);
  };
  const handlewebChange = (event) => {
    setWeb(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const formValid = (event) => {
    //Zip
    var validZip = new RegExp(/\d{5}/);
    zipValid = validZip.test(venueZip);
    //URL
    var validUrl = new RegExp(
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    );
    urlValid = validUrl.test(web);
    //Phone
    var validPhone = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
    phoneValid = validPhone.test(phone);

    if (
      venueName != "" &&
      zipValid &&
      phoneValid &&
      urlValid &&
      venueAddress != "" &&
      venueCity != ""
    ) {
      dataValid = true;
    } else if (venueName == "") {
      setErrorMessage("The Venue Name Cannot be blank");
      setAlert1(true);
    } else if (!zipValid) {
      console.log(zipValid);
      setErrorMessage("The zip code you provided is invalid");
      setAlert1(true);
    } else if (!phoneValid) {
      setErrorMessage("The phone number you provided is invalid");
      setAlert1(true);
    } else if (!urlValid) {
      setErrorMessage("The URL you provided is invalid");
      setAlert1(true);
    } else if (venueCity == "") {
      setErrorMessage("The Venue City Cannot be blank");
      setAlert1(true);
    } else if (venueAddress == "") {
      setErrorMessage("The Venue Address Cannot be blank");
      setAlert1(true);
    }
    setEmail(user.email);
  };

  const downloadTerms = (event) => {
    console.log("downloadzzz");
    window.location.href =
      "../../assets/downloads/Venue Terms of Service - LYZT.pdf";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    formValid();
    if (!dataValid) {
      setAlert1(true);
      setIsLoading(false);
      return;
    } else {
      const uid = user.uid;
      setEmail(user.email);
      const newVenue = db.collection("venues").doc();
      const venueID = newVenue.id;
      setVenueId(venueID);
      venueIdForStripe = venueID;
      newVenue.set({
        activePasses: 20,
        activeVenue: false,
        stripeConnectID: "",
        venueAddress: venueAddress,
        venueCity: venueCity,
        venueState: venState,
        venueDescription: "",
        venueID: venueID,
        venueImages: [""],
        venueLocation: "1",
        venueLocation2: "1",
        venueName: venueName,
        venuePhoto: "",
        venuePhone: phone,
        venuePrice: 500,
        venueWeb: web,
        venueZip: venueZip,
        passResetAutomation: true,
        passResetAmount: 20,
      });
      db.collection("users")
        .doc(uid)
        .update(
          {
            M: venueID,
          },
          { merge: true }
        )

        .then(() => {
          setVenueId(venueIdForStripe);
          stripeConnectRedirect();
          //history.push('/index')
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error writing document: ", error);
        });
    }
  };
  const handleSignOut = async (event) => {
    event.preventDefault();

    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        history.push("/login-page");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const getState = (zipString) => {
    /* Ensure param is a string to prevent unpredictable parsing results */
    if (typeof zipString !== "string") {
      //console.log('Must pass the zipcode as a string.');
      return;
    }

    /* Ensure we have exactly 5 characters to parse */
    if (zipString.length !== 5) {
      // console.log('Must pass a 5-digit zipcode.');
      return;
    }

    /* Ensure we don't parse strings starting with 0 as octal values */
    const zipcode = parseInt(zipString, 10);

    let st;
    let state;

    /* Code cases alphabetized by state */
    if (zipcode >= 35000 && zipcode <= 36999) {
      st = "AL";
      state = "Alabama";
    } else if (zipcode >= 99500 && zipcode <= 99999) {
      st = "AK";
      state = "Alaska";
    } else if (zipcode >= 85000 && zipcode <= 86999) {
      st = "AZ";
      state = "Arizona";
    } else if (zipcode >= 71600 && zipcode <= 72999) {
      st = "AR";
      state = "Arkansas";
    } else if (zipcode >= 90000 && zipcode <= 96699) {
      st = "CA";
      state = "California";
    } else if (zipcode >= 80000 && zipcode <= 81999) {
      st = "CO";
      state = "Colorado";
    } else if (
      (zipcode >= 6000 && zipcode <= 6389) ||
      (zipcode >= 6391 && zipcode <= 6999)
    ) {
      st = "CT";
      state = "Connecticut";
    } else if (zipcode >= 19700 && zipcode <= 19999) {
      st = "DE";
      state = "Delaware";
    } else if (zipcode >= 32000 && zipcode <= 34999) {
      st = "FL";
      state = "Florida";
    } else if (
      (zipcode >= 30000 && zipcode <= 31999) ||
      (zipcode >= 39800 && zipcode <= 39999)
    ) {
      st = "GA";
      state = "Georgia";
    } else if (zipcode >= 96700 && zipcode <= 96999) {
      st = "HI";
      state = "Hawaii";
    } else if (zipcode >= 83200 && zipcode <= 83999) {
      st = "ID";
      state = "Idaho";
    } else if (zipcode >= 60000 && zipcode <= 62999) {
      st = "IL";
      state = "Illinois";
    } else if (zipcode >= 46000 && zipcode <= 47999) {
      st = "IN";
      state = "Indiana";
    } else if (zipcode >= 50000 && zipcode <= 52999) {
      st = "IA";
      state = "Iowa";
    } else if (zipcode >= 66000 && zipcode <= 67999) {
      st = "KS";
      state = "Kansas";
    } else if (zipcode >= 40000 && zipcode <= 42999) {
      st = "KY";
      state = "Kentucky";
    } else if (zipcode >= 70000 && zipcode <= 71599) {
      st = "LA";
      state = "Louisiana";
    } else if (zipcode >= 3900 && zipcode <= 4999) {
      st = "ME";
      state = "Maine";
    } else if (zipcode >= 20600 && zipcode <= 21999) {
      st = "MD";
      state = "Maryland";
    } else if (
      (zipcode >= 1000 && zipcode <= 2799) ||
      zipcode == 5501 ||
      zipcode == 5544
    ) {
      st = "MA";
      state = "Massachusetts";
    } else if (zipcode >= 48000 && zipcode <= 49999) {
      st = "MI";
      state = "Michigan";
    } else if (zipcode >= 55000 && zipcode <= 56899) {
      st = "MN";
      state = "Minnesota";
    } else if (zipcode >= 38600 && zipcode <= 39999) {
      st = "MS";
      state = "Mississippi";
    } else if (zipcode >= 63000 && zipcode <= 65999) {
      st = "MO";
      state = "Missouri";
    } else if (zipcode >= 59000 && zipcode <= 59999) {
      st = "MT";
      state = "Montana";
    } else if (zipcode >= 27000 && zipcode <= 28999) {
      st = "NC";
      state = "North Carolina";
    } else if (zipcode >= 58000 && zipcode <= 58999) {
      st = "ND";
      state = "North Dakota";
    } else if (zipcode >= 68000 && zipcode <= 69999) {
      st = "NE";
      state = "Nebraska";
    } else if (zipcode >= 88900 && zipcode <= 89999) {
      st = "NV";
      state = "Nevada";
    } else if (zipcode >= 3000 && zipcode <= 3899) {
      st = "NH";
      state = "New Hampshire";
    } else if (zipcode >= 7000 && zipcode <= 8999) {
      st = "NJ";
      state = "New Jersey";
    } else if (zipcode >= 87000 && zipcode <= 88499) {
      st = "NM";
      state = "New Mexico";
    } else if (
      (zipcode >= 10000 && zipcode <= 14999) ||
      zipcode == 6390 ||
      zipcode == 501 ||
      zipcode == 544
    ) {
      st = "NY";
      state = "New York";
    } else if (zipcode >= 43000 && zipcode <= 45999) {
      st = "OH";
      state = "Ohio";
    } else if (
      (zipcode >= 73000 && zipcode <= 73199) ||
      (zipcode >= 73400 && zipcode <= 74999)
    ) {
      st = "OK";
      state = "Oklahoma";
    } else if (zipcode >= 97000 && zipcode <= 97999) {
      st = "OR";
      state = "Oregon";
    } else if (zipcode >= 15000 && zipcode <= 19699) {
      st = "PA";
      state = "Pennsylvania";
    } else if (zipcode >= 300 && zipcode <= 999) {
      st = "PR";
      state = "Puerto Rico";
    } else if (zipcode >= 2800 && zipcode <= 2999) {
      st = "RI";
      state = "Rhode Island";
    } else if (zipcode >= 29000 && zipcode <= 29999) {
      st = "SC";
      state = "South Carolina";
    } else if (zipcode >= 57000 && zipcode <= 57999) {
      st = "SD";
      state = "South Dakota";
    } else if (zipcode >= 37000 && zipcode <= 38599) {
      st = "TN";
      state = "Tennessee";
    } else if (
      (zipcode >= 75000 && zipcode <= 79999) ||
      (zipcode >= 73301 && zipcode <= 73399) ||
      (zipcode >= 88500 && zipcode <= 88599)
    ) {
      st = "TX";
      state = "Texas";
    } else if (zipcode >= 84000 && zipcode <= 84999) {
      st = "UT";
      state = "Utah";
    } else if (zipcode >= 5000 && zipcode <= 5999) {
      st = "VT";
      state = "Vermont";
    } else if (
      (zipcode >= 20100 && zipcode <= 20199) ||
      (zipcode >= 22000 && zipcode <= 24699) ||
      zipcode == 20598
    ) {
      st = "VA";
      state = "Virginia";
    } else if (
      (zipcode >= 20000 && zipcode <= 20099) ||
      (zipcode >= 20200 && zipcode <= 20599) ||
      (zipcode >= 56900 && zipcode <= 56999)
    ) {
      st = "DC";
      state = "Washington DC";
    } else if (zipcode >= 98000 && zipcode <= 99499) {
      st = "WA";
      state = "Washington";
    } else if (zipcode >= 24700 && zipcode <= 26999) {
      st = "WV";
      state = "West Virginia";
    } else if (zipcode >= 53000 && zipcode <= 54999) {
      st = "WI";
      state = "Wisconsin";
    } else if (zipcode >= 82000 && zipcode <= 83199) {
      st = "WY";
      state = "Wyoming";
    } else {
      st = "none";
      state = "none";
      //console.log('No state found matching', zipcode);
    }

    return st;
  };

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      {isLoading ? <LoadingSpinner /> : <center></center>}
      <VenueNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section">
          <Container>
            <div className="button-container">
              <Button
                style={{
                  fontSize: "20px",
                  backgroundColor: "black",
                  fontWeight: "bolder",
                }}
              >
                Welcome To Lyzt OnBoarding!
              </Button>
            </div>
            <Card className="card-plain">
              <center>
                <p style={{ width: "90%" }}>
                  Thank you for being our newest partner!
                  <br />
                  <br />
                  The On-Boarding Process is relatively simple and consists of
                  three basic steps: <br />
                  <br />
                  1. The first requies you enter some basic information about
                  your venue, and agree to our Venue Terms of Service.
                  <br />
                  <br />
                  2. Second, you will be forwarded to Stripe, our payment
                  processor. The Lyzt uses{" "}
                  <a
                    href="https://stripe.com/connect"
                    target="_blank"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Stripe Connect
                  </a>{" "}
                  to securely collect information about your finances, allowing
                  us to instantly transfer you the fees your venue collects
                  through the Lyzt platform.
                  <br />
                  <br />
                  3. Lastly, you will be forwarded to the Lyzt's Venue Portal
                  where you can set prices, add photos, and make other final
                  adjustments before your venue is listed live on the Lyzt app.
                  <br />
                  <br />
                  Questions? Contact us anytime for assistance at:{" "}
                  <a
                    style={{ color: "black", fontWeight: "bold" }}
                    href="mailto:support@thelyzt.com"
                  >
                    {" "}
                    support@thelyzt.com{" "}
                  </a>
                  or through your sales contact.
                </p>
              </center>
            </Card>

            <center>
              <h2 style={{ marginBottom: "0px" }}>Basic Venue Information</h2>
            </center>
            <Card className="card-plain">
              <Form onSubmit={handleSubmit} action="" name="form" method="">
                <CardBody>
                  <center>
                    <p>
                      Please enter the requested information about your venue.
                      Don't Stress, this can all be changed later.
                    </p>
                  </center>
                  <InputGroup
                    className={
                      "no-border input-lg" +
                      (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons objects_diamond"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Venue Name"
                      name="venueName"
                      type="text"
                      onChange={handleVenNameChange}
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                      value={venueName}
                    ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "no-border input-lg" +
                      (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons location_pin"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Street Address"
                      name="venueAddress"
                      type="text"
                      onChange={handleVenAddressChange}
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                      value={venueAddress}
                    ></Input>
                    <Input
                      placeholder="City"
                      name="venueCity"
                      type="text"
                      onChange={handleVenCityChange}
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                      value={venueCity}
                    ></Input>
                    <Input
                      placeholder="Zip Code"
                      name="venueZip"
                      type="text"
                      onChange={handleVenZipChange}
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                      value={venueZip}
                    ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "no-border input-lg" +
                      (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons objects_globe"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Website Address"
                      name="web"
                      type="url"
                      onChange={handlewebChange}
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                      value={web}
                    ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "no-border input-lg" +
                      (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons tech_mobile"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone Number"
                      name="phone"
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      required
                      onChange={handlePhoneChange}
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                      value={phone}
                    ></Input>
                  </InputGroup>
                </CardBody>
                <CardFooter className="text-center">
                  <Alert color={alertType} isOpen={alert1}>
                    <Container>
                      {errorMessage}
                      <button
                        type="button"
                        className="close"
                        onClick={() => setAlert1(false)}
                      >
                        <span aria-hidden="true">
                          <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                      </button>
                    </Container>
                  </Alert>
                  <Button
                    block
                    className="btn"
                    color="black"
                    disabled={isLoading}
                    onClick={handleSubmit}
                    size="lg"
                  >
                    Save & Continue to Stripe
                  </Button>
                  <div>
                    By clicking you certify that you agree to the{" "}
                    <strong>Terms of Service</strong> outlined below.
                  </div>
                </CardFooter>
              </Form>
            </Card>

            <center>
              <h2 style={{ marginBottom: "0px" }}>Venue Terms of Service</h2>
            </center>
            <iframe
              style={{ width: "100%", border: "none", height: "25em" }}
              src="vterms.html"
            ></iframe>

            <Row>
              <Button
                download="Lyzt Venue Terms of Service.pdf"
                block
                href="vterms.pdf"
              >
                Download Terms of Service
              </Button>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default OnBoardPage;
