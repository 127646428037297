import React from "react";
import { signInWithGoogle } from "firebase/firebase.utils";
//import { auth } from "firebase/firebase.utils";
import { doc, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable, setData } from "firebase/functions";
import firebase from "firebase/compat/app";

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  OAuthProvider,
  signOut,
} from "firebase/auth";
import { useHistory } from "react-router";
import { db } from "firebase/firebase.utils";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import LoginNavbar from "components/Navbars/LoginNavbar";
import { Timestamp } from "firebase/firestore";
const auth = firebase.auth();

function SignUpPage() {
  let history = useHistory();
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [user, setUser] = React.useState("");
  const [appUser, setAppUser] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [cPassword, setConfirmPassword] = React.useState("");
  const [fName, setFName] = React.useState("");
  const [lName, setLName] = React.useState("");
  const [hideSecret, setHideSecret] = React.useState("");
  const [hideAll, setHideAll] = React.useState("none");
  const [secret, setSecret] = React.useState();
  const [passErrorMessage, setPassErrorMessage] = React.useState("");

  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      var docRef = db.collection("users").doc(uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            var userData = doc.data();
            const M = userData.M;
            const onBoard = userData.onBoard;
            //console.log(onBoard)
            if (onBoard == false && M != "") {
              history.push("/venue");
            } else if (onBoard == true && M == "") {
              history.push("/onboard");
            } else if (M != "" && onBoard == true) {
              history.push("/onboardcompletion");
            } else {
              //setErrorMessage(
              //   "The web portal is currently only for Venue Managers. This account is not currently associated with a venue."
              // );
              //signOut(auth);
            }
          } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });

      //history.push("/user")
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleFNameChange = (event) => {
    setFName(event.target.value);
  };
  const handleLNameChange = (event) => {
    setLName(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSecret = (event) => {
    setSecret(event.target.value);
    setPassErrorMessage("");
  };
  const checkValidation = async () => {
    var regexEmail = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    var emailValid = regexEmail.test(email);

    var regexPassword = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=\D*\d)(?=.*[@#$_%\*!])([a-zA-Z][\w@#$!%*]{7,})$/
    );
    var passwordValid = regexPassword.test(password);

    if (
      email == "" ||
      fName == "" ||
      lName == "" ||
      email == "" ||
      password == ""
    ) {
      setErrorMessage("Please type all the required information.");
    } else if (password != cPassword) {
      setErrorMessage("The password and confirm password fields do not match.");
    } else if (!emailValid) {
      setErrorMessage("Please enter a valid email address.");
    } else if (!passwordValid) {
      setErrorMessage(
        "Your password needs to contain at least 8 characters and at least one uppercase letter, one lowercase letter, one number, and one special character. "
      );
    } else {
      setErrorMessage("Account Created!");
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user;
          //console.log(user);

          db.collection("users")
            .doc(user.uid)
            .set({
              B: "",
              M: "",
              docid: user.uid,
              email: email,
              firstName: fName,
              id: user.uid,
              lastName: lName,
              privacySign: new Timestamp(0, 0),
              termsSign: new Timestamp(0, 0),
              uid: user.uid,
              userPhoto: "user.jpeg",
              value: 0,
              onBoard: true,
            })
            .then(() => {
              //console.log("Document successfully written!");
              const functions = getFunctions();
              const data = { email: email };
              const createUser = httpsCallable(functions, "createStripeUser");

              createUser(data).then((result) => {
                const data = result.data;
                //console.log(data);
                history.push("/venue");
              });
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessageF = error.message;
          console.error(errorMessageF);

          // ..
        });
    }
  };

  const handleAppleSignIn = async (event) => {
    event.preventDefault();

    const appleProvider = new OAuthProvider("apple.com");
    appleProvider.addScope("email");
    appleProvider.addScope("name");

    const auth = getAuth();
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        // ...
      });
  };

  const googleButton = async (event) => {
    await signInWithGoogle();

    var dbRef = db.collection("users").doc(auth.currentUser.uid);
    await dbRef.get().then((doc) => {
      if (!doc.exists) {
        dbRef
          .set({
            B: "",
            M: "",
            docid: auth.currentUser.uid,
            email: auth.currentUser.email,
            firstName: auth.currentUser.email,
            id: auth.currentUser.uid,
            lastName: "",
            privacySign: new Timestamp(0, 0),
            termsSign: new Timestamp(0, 0),
            uid: auth.currentUser.uid,
            userPhoto: "user.jpeg",
            value: 0,
            onBoard: true,
          })
          .then(() => {
            const functions = getFunctions();
            const data = { email: auth.currentUser.email };
            const createUser = httpsCallable(functions, "createStripeUser");

            createUser(data).then((result) => {
              const data = result.data;
            });
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
        history.push("/onboard");
      } else {
        history.push("/venue");
      }
    });
  };

  const checkPassPhrase = () => {
    if (secret == "ly2t2022") {
      setHideAll("");
      setHideSecret("none");
    } else {
      setPassErrorMessage("The passphrase you entered is incorrect.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const auth = getAuth();
    checkValidation();
  };
  const handleSubmit2 = async (event) => {
    event.preventDefault();

    alert(`Your state values: \n 
            email: ${email} \n 
            You can replace this alert with your process`);

    //this.setState({email: "", password: ""})
  };

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="page-header clear-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/clubbg.jpg").default + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <LoginNavbar />
            <Col className="ml-auto mr-auto" md="4">
              <Card
                className="card-login card-plain"
                style={{ display: hideSecret }}
              >
                <CardHeader className="text-center">
                  <div className="">
                    <img
                      alt="..."
                      src={require("assets/img/newLyztWhite.png").default}
                      style={{ maxHeight: "250px" }}
                    ></img>
                  </div>
                </CardHeader>
                <CardBody>
                  <p>
                    In order to create a venue account, please enter the
                    passphrase provided by the sales team.
                  </p>
                  <InputGroup
                    className={
                      "no-border input-lg" +
                      (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons objects_key-25"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Passphrase"
                      type="password"
                      onChange={handleSecret}
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                      value={secret}
                    ></Input>
                  </InputGroup>
                  <p>{passErrorMessage}</p>
                  <Button onClick={checkPassPhrase}>Continue</Button>
                </CardBody>
              </Card>
              <Card
                className="card-login card-plain"
                style={{ display: hideAll }}
              >
                <Form
                  onSubmit={handleSubmit}
                  action=""
                  className="form"
                  method=""
                >
                  <CardHeader className="text-center">
                    <div className="">
                      <img
                        alt="..."
                        src={require("assets/img/newLyztWhite.png").default}
                        style={{ maxHeight: "250px" }}
                      ></img>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="First Name"
                        name="fName"
                        type="text"
                        onChange={handleFNameChange}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        value={fName}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Last Name"
                        name="lName"
                        type="lName"
                        onChange={handleLNameChange}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        value={lName}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email Address"
                        name="email"
                        type="email"
                        onChange={handleEmailChange}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        value={email}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons objects_key-25"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        name="password"
                        type="password"
                        onChange={handlePasswordChange}
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                        value={password}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons objects_key-25"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm Password"
                        name="cPassword"
                        type="Password"
                        onChange={handleConfirmPasswordChange}
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                        value={cPassword}
                      ></Input>
                    </InputGroup>
                    <div style={{ color: "red" }}>{errorMessage}</div>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn"
                      color="black"
                      href="#pablo"
                      onClick={handleSubmit}
                      size="lg"
                    >
                      Create Account
                    </Button>
                    <Button
                      block
                      className="btn"
                      onClick={googleButton}
                      size="lg"
                    >
                      <img
                        alt="..."
                        src={require("assets/img/google.png").default}
                        style={{ maxHeight: "25px" }}
                      ></img>
                      &nbsp; &nbsp; Continue with Google
                    </Button>
                    <div>
                      <h6>
                        <a className="link" href="/login-page">
                          Already Have an Account? Log In.
                        </a>
                      </h6>
                    </div>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SignUpPage;
