import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { OAuthProvider } from "firebase/auth";
import { getAuth } from "firebase/auth";
import "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyCUgMckJxr29PFZa3f2dzPmcQTCapnfjog",
  authDomain: "jack-s-list.firebaseapp.com",
  databaseURL: "https://jack-s-list-default-rtdb.firebaseio.com",
  projectId: "jack-s-list",
  storageBucket: "jack-s-list.appspot.com",
  messagingSenderId: "675948561704",
  appId: "1:675948561704:web:40e84de34c30bad1927c1d",
};

// Initialize Firebase

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const app = initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const functions = getFunctions(app);
export const storage = getStorage(app);

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = async () => {
  await auth.signInWithPopup(provider);
};

export const getUserInfo = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    let uid = user.uid;
  }
};

export default firebase;
