import React from "react";
import { Link } from "react-router-dom";

import { signInWithGoogle } from "firebase/firebase.utils";
import AppStoreButton from "react-appstore-button";
// reactstrap component
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
} from "reactstrap";

// core components

function SignUp() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  return (
    <>
      <div
        className="section section-signup"
        style={{
          backgroundImage:
            "url(" + require("assets/img/girls.jpeg").default + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "500px",
        }}
      >
        <Container>
          <Card
            className="card-plain"
            data-background-color="transparent"
            style={{ opacity: 1 }}
          >
            <Form action="" className="form" method="">
              <CardHeader className="text-center">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h2
                  style={{ fontWeight: "800", textShadow: "4px 4px 4px #000" }}
                >
                  #GetOnTheLyzt
                </h2>
                <AppStoreButton
                  //url="https://itunes.apple.com/us/app/my-nbn/id1247260936?mt=8"
                  url="/download"
                ></AppStoreButton>
              </CardHeader>
            </Form>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default SignUp;
