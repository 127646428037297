import React from "react";
import { signInWithGoogle } from "firebase/firebase.utils";
import { auth } from "firebase/firebase.utils";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  OAuthProvider,
  signOut,
} from "firebase/auth";
import { useHistory } from "react-router";
import { db } from "firebase/firebase.utils";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import LoginNavbar from "components/Navbars/LoginNavbar";

function DownloadPage() {
  let history = useHistory();
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrorMessage("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var regexEmail = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    var emailValid = regexEmail.test(email);

    if (email == "") {
      setErrorMessage("Please type in your email address.");
      return;
    }
    if (!emailValid) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    const newVenue = db.collection("emailList").doc(email);
    newVenue
      .set(
        {
          email: email,
        },
        { merge: true }
      )
      .then(() => {
        // console.log(`Document ${venueID} successfully written!`);
        // setAlertMessage("Passes Updated.")
        // setAlert1(true)
        // setAlertType("success")
        setErrorMessage("Added to the Lyzt's List!");
        //window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        // setAlertMessage("Error writing document: ", error)
        // setAlert1(true)
        // setAlertType("danger")
        window.scrollTo(0, 0);
      });
  };

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="page-header clear-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/clubbg.jpg").default + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <LoginNavbar />
            <Col className="ml-auto mr-auto" md="6">
              <Card className="card-plain">
                <Form
                  onSubmit={handleSubmit}
                  action=""
                  className="form"
                  method=""
                >
                  <CardHeader className="text-center">
                    <div className="">
                      <img
                        alt="..."
                        src={require("assets/img/newLyztWhite.png").default}
                        style={{ maxHeight: "250px" }}
                      ></img>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <h5>
                      The Lyzt is still in development. <br></br>
                      <br></br>If you want to be notified when we go live, enter
                      your email address here:
                    </h5>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email Address"
                        name="email"
                        type="text"
                        onChange={handleEmailChange}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        value={email}
                      ></Input>
                    </InputGroup>

                    <div>{errorMessage}</div>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn"
                      color="black"
                      href="#pablo"
                      onClick={handleSubmit}
                      size="lg"
                    >
                      Get on the list... to <strong>Get on the Lyzt!</strong>
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default DownloadPage;
