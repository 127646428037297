import React, { useEffect } from "react";
import { Input, Button, Card } from "reactstrap";
import { useState } from "react";
import { PictureAsPdfSharp } from "@mui/icons-material";
import { db } from "firebase/firebase.utils";
import { getStorage, ref, getDownloadURL, uploadBytesResumable, uploadBytes } from "firebase/storage";
import { storage } from "firebase/firebase.utils";

export function BouncerElement(props) {
    const { data, fullArray, venueID } = props;

    var bouncerArray = props.fullArray
    var bouncer = props.data
    
    
    const [url, setURL] = React.useState("")
    const [urlValidMessage, setUrlValidMessage] = React.useState("")
    const [isActive, setIsActive] = React.useState(true)
    const [bouncerPic, setBouncerPic] = React.useState();
    

    var arraySize = props.fullArray.length

const handlePicURLChange = event => {
    var validUrl = new RegExp( /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);
    setURL(event.target.value)
    if (!validUrl.test(event.target.value)){
        setUrlValidMessage("This is not a valid URL")
    } else {setUrlValidMessage("")}
}

const removeBouncer = () => {
    setIsActive(false)
    
    db.collection("users").doc(props.bouncer.bouncerUID).update({
        B:""
    }, {merge: true})

    db.collection("venues").doc(venueID).collection("bouncers").doc(props.bouncer.bouncerUID).delete().then(() => {
        console.log("Document successfully deleted!");
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
}

React.useEffect(() => {
    setURL(props.imageUrl)
    var bouncerid = props.bouncer.bouncerUID
    var bouncerStorageRef = ref(storage, "User/" + bouncerid + "_500x500")
    getDownloadURL(bouncerStorageRef)
    .then((url) => {
        setBouncerPic(url)          
    }).catch((err) => {
    // Didn't exist... or some other error
    //console.log(err)
    var storageRef = ref(storage, "Venue/DdBluhNta5RxFMTDibBM.jpeg")
    getDownloadURL(storageRef)
    .then((url) => {
        setBouncerPic(url)          
    })
    })


  }, [])

if (!isActive) {return false}

return (
    <>
    <Card style={{padding:"15px", borderRadius:"20px"}}>
        <center>
            <img src={bouncerPic} style={{borderRadius:"50%", height:"200px", width:"200px", objectFit:"cover"}} />
            <p>{props.bouncer.name}
             <br />{props.bouncer.email}</p>
            <Button onClick={removeBouncer} block>Remove Bouncer</Button>
        </center>

        
    </Card>
    </>
  )

}