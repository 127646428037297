import React from "react";
import { signInWithGoogle } from "firebase/firebase.utils";
import { auth } from "firebase/firebase.utils";
import { Timestamp } from "firebase/firestore";
import { getFunctions, httpsCallable, setData } from "firebase/functions";

import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  OAuthProvider,
  signOut,
} from "firebase/auth";
import { useHistory } from "react-router";
import { db } from "firebase/firebase.utils";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import LoginNavbar from "components/Navbars/LoginNavbar";

function LoginPage() {
  let history = useHistory();
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [user, setUser] = React.useState("");
  const [appUser, setAppUser] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("");

  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      //console.log("user logged in");// User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      var docRef = db.collection("users").doc(uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            var userData = doc.data();
            const M = userData.M;
            const onBoard = userData.onBoard;
            //console.log(onBoard)
            if (onBoard == false && M != "") {
              history.push("/venue");
            } else if (onBoard == true && M == "") {
              history.push("/onboard");
            } else if (M != "" && onBoard == true) {
              history.push("/onboardcompletion");
            } else {
              setErrorMessage(
                "The web portal is currently only for Venue Managers. This account is not currently associated with a venue."
              );
              signOut(auth);
            }
          } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });

      //history.push("/user")
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleAppleSignIn = async (event) => {
    event.preventDefault();

    const appleProvider = new OAuthProvider("apple.com");
    appleProvider.addScope("email");
    appleProvider.addScope("name");

    const auth = getAuth();
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        // ...
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Log In Complete!");
        const user = userCredential.user;
        console.log(user);
        setEmail(user.email);
        setPassword("");
        setUser(user);

        //history.push("/index");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        setErrorMessage(error.message);
        console.log(error);
      });
  };
  const googleButton = async (event) => {
    await signInWithGoogle();

    var dbRef = db.collection("users").doc(auth.currentUser.uid);
    await dbRef.get().then((doc) => {
      if (!doc.exists) {
        dbRef
          .set({
            B: "",
            M: "",
            docid: auth.currentUser.uid,
            email: auth.currentUser.email,
            firstName: auth.currentUser.email,
            id: auth.currentUser.uid,
            lastName: "",
            privacySign: new Timestamp(0, 0),
            termsSign: new Timestamp(0, 0),
            uid: auth.currentUser.uid,
            userPhoto: "user.jpeg",
            value: 0,
            onBoard: true,
          })
          .then(() => {
            const functions = getFunctions();
            const data = { email: auth.currentUser.email };
            const createUser = httpsCallable(functions, "createStripeUser");

            createUser(data).then((result) => {
              const data = result.data;
            });
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
        history.push("/onboard");
      } else {
        history.push("/venue");
      }
    });
  };

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="page-header clear-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/clubbg.jpg").default + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <LoginNavbar />
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form
                  onSubmit={handleSubmit}
                  action=""
                  className="form"
                  method=""
                >
                  <CardHeader className="text-center">
                    <div className="">
                      <img
                        alt="..."
                        src={require("assets/img/newLyztWhite.png").default}
                        style={{ maxHeight: "250px" }}
                      ></img>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email Address"
                        name="email"
                        type="email"
                        onChange={handleEmailChange}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        value={email}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons objects_key-25"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        name="password"
                        type="password"
                        onChange={handlePasswordChange}
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                        value={password}
                      ></Input>
                    </InputGroup>
                    <div>{errorMessage}</div>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn"
                      color="black"
                      href="#pablo"
                      onClick={handleSubmit}
                      size="lg"
                    >
                      Sign In
                    </Button>
                    <Button
                      block
                      className="btn"
                      onClick={googleButton}
                      size="lg"
                    >
                      <img
                        alt="..."
                        src={require("assets/img/google.png").default}
                        style={{ maxHeight: "25px" }}
                      ></img>
                      &nbsp; &nbsp; Continue with Google
                    </Button>

                    <div>
                      <h6>
                        <a className="link" href="/signup">
                          Create Account
                        </a>
                      </h6>
                    </div>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
