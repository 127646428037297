import React, { useReducer, useState } from "react";
import { db, firebaseConfig } from "firebase/firebase.utils";
// reactstrap components
import { Container } from "reactstrap";
import { getUserInfo } from "firebase/firebase.utils";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, setDoc, getDoc, initializeFirestore } from "firebase/firestore"; 
import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import "firebase/firestore";
import firebase from 'firebase/compat/app';


// core components

initializeApp(firebaseConfig)


function VenueHeader() {
  
  //getUserInfo();
  const [appUser, setAppUser] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [uid, setUid] = React.useState('');
  const [avatar, setAvatar] = React.useState([]);

  const storage = getStorage();
  const avatarRef = ref(storage, "Users//");

  const auth = getAuth();
// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     const uid = user.uid;
//     setEmail(user.email)
//       var docRef = db.collection("users").doc(uid);
//         docRef.get().then((doc) => {
//             if (doc.exists) {
//                 setAppUser(doc.data())
//             } else {
//                 // doc.data() will be undefined in this case
//                 //console.log("No such document!");
//             }
//         }).catch((error) => {
//             console.log("Error getting document:", error);
//         });
//   } else {
//     // User is signed out
//     // ...
//   }
// });


 
  
  let pageHeader = React.createRef();
  
  // React.useEffect(() => {
  //   listAll(avatarRef).then((response) => {
  //     console.log(response)
  //   })
    
  // }, []);

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 6;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div 
        className="page-header clear-filter page-header-small"
        filter-color="black"
        style={{minHeight:"30vh", height:"100px"}}
      >
        <div
          className="page-header-image"
          style={{
            minHeight:"30vh",
            backgroundImage:
              "url(" + require("assets/img/clubbg.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <h1 style={{fonFamily:"Avenir Roman", fontWeight:"bold"}}>Venue Portal</h1>
          
        </Container>
      </div>
    </>
  );
}

export default VenueHeader;
