/*

=========================================================
* Now UI Kit React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LoginPage from "views/examples/LoginPage.js";
import LandingPage from "views/examples/LandingPage.js";
//import ProfilePage from "views/examples/ProfilePage.js";
import DownloadPage from "views/examples/DownloadPage.js";
import VenuePortal from "views/examples/VenuePortal.js";
import Test from "views/examples/Test.js";
import OnBoardPage from "views/examples/OnBoardPage";
import OnBoardCompletionPage from "views/examples/OnBoardCompletionPage";
import PrivacyPage from "views/examples/PrivacyPage";
import TermsPage from "views/examples/TermsPage";
import VenueTermsPage from "views/examples/VenueTermsPage";
import CustomPaginationActionsTable from "views/examples/TableTest";
import SignUpPage from "views/examples/SignUpPage";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />

        <Route
          path="/login-page"
          render={(props) => <LoginPage {...props} />}
        />
        <Route path="/onboard" render={(props) => <OnBoardPage {...props} />} />
        <Route
          path="/onboardcompletion"
          render={(props) => <OnBoardCompletionPage {...props} />}
        />
        <Route
          path="/download"
          render={(props) => <DownloadPage {...props} />}
        />
        <Route
          path="/download.html"
          render={(props) => <DownloadPage {...props} />}
        />
        <Route path="/venue" render={(props) => <VenuePortal {...props} />} />
        <Route path="/privacy" render={(props) => <PrivacyPage {...props} />} />
        <Route path="/terms" render={(props) => <TermsPage {...props} />} />
        <Route
          path="/venueterms"
          render={(props) => <VenueTermsPage {...props} />}
        />
        <Route path="/signup" render={(props) => <SignUpPage {...props} />} />
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
