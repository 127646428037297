/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black" id="bottom">
      <Container>
        <nav>
          
        </nav>
        <center><div className="copyright" id="copyright">
            © {new Date().getFullYear()}, The Lyzt Inc.
          </div>
          <br />
          <a style={{color:"white"}} href="/terms"> Terms of Service </a> | 
          <a style={{color:"white"}} href="/privacy"> Privacy Policy </a> | 
          <a style={{color:"white"}} href="/venueterms"> Venue Terms </a>
          <br />
          Contact Us: <a style={{color:"white"}} href="mailto:support@thelyzt.com"> support@thelyzt.com </a>
          </center>
      </Container>
    </footer>
  );
}

export default DarkFooter;
