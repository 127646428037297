import React from "react";
import { signInWithGoogle } from "firebase/firebase.utils";
import { auth } from "firebase/firebase.utils";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signInWithPopup, OAuthProvider, signOut } from "firebase/auth";
import { useHistory } from "react-router";
import { db } from "firebase/firebase.utils";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import LoginNavbar from "components/Navbars/LoginNavbar";
import { isWhiteSpaceSingleLine } from "typescript";
import DarkFooter from "components/Footers/DarkFooter";


function PrivacyPage() {

  let history = useHistory();
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const [errorMessage, setErrorMessage] = React.useState("");

  const handleEmailChange = event => {
    setEmail(event.target.value)
  };

  const handleSubmit = async event => {
    event.preventDefault();
  
    alert(`NOT added to the thing! THIS ISN'T SET UP YET!`);
  }

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      
      <div className="page-header clear-filter" filter-color="black" backgroundColor="white">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/clubbg.jpg").default + ")",
          }}
        ></div>
        <div className="content">
          <Container>
          <LoginNavbar />
            <h1 style={{fontWeight:"bold"}}>Privacy Policy</h1>
            <iframe style={{marginBotton: "20px", padding: "2em", width:"100%", border:"none", height:"400px", backgroundColor:"white"}}src="privacy.html"></iframe>
            <Button 
                download="Lyzt Privacy Policy.pdf"
                block
                href="privacy.pdf">
                Download Privacy Policy
            </Button>
          </Container>
        </div>
        
      </div><DarkFooter />
    </>
  );
}

export default PrivacyPage;
