import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Images2() {
  const [currentImage, setCurrentImage] = React.useState("assets/img/SS2.png");
  const [hiddenOne, setHiddenOne] = React.useState(false);
  const [hiddenTwo, setHiddenTwo] = React.useState(true);
  const [hiddenThree, setHiddenThree] = React.useState(true);

  var count = 1;
  const switchPic = () => {
    if ((count = 1)) {
      setHiddenOne(true);
      setHiddenTwo(false);
      setHiddenThree(true);
    } else if ((count = 2)) {
      setHiddenOne(true);
      setHiddenTwo(true);
      setHiddenThree(false);
    } else {
      setHiddenOne(false);
      setHiddenTwo(true);
      setHiddenThree(true);
      count = 1;
    }
  };

  React.useEffect(() => {
    setInterval(switchPic, 400);
  }, [switchPic]);

  return (
    <>
      <div className="section section-images">
        <Container>
          <Col md="3">
            <div className="hero-images-container-1">
              <img
                alt="..."
                src={require("assets/img/SS1.png").default}
                hidden={false}
              ></img>
            </div>
            <div className="hero-images-container-1">
              <img
                alt="..."
                src={require("assets/img/SS2.png").default}
                hidden={true}
              ></img>
            </div>
            <div className="hero-images-container-1">
              <img
                alt="..."
                src={require("assets/img/SS3.png").default}
                hidden={true}
              ></img>
            </div>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default Images2;
