/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <nav>
            
              
          </nav>
          <center><div className="copyright" id="copyright">
            © {new Date().getFullYear()}, The Lyzt Inc.
          </div>
          <br />
          <a style={{color:"black"}} href="/terms"> Terms of Service </a> | 
          <a style={{color:"black"}} href="/privacy"> Privacy Policy </a> | 
          <a style={{color:"black"}} href="/venueterms"> Venue Terms </a>
          <br />
          Contact Us: <a style={{color:"black"}} href="mailto:support@thelyzt.com"> support@thelyzt.com </a>
          
          
          </center>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
