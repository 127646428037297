import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container" style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      minHeight: "100%",
      minWidth: "100%",
      position: "fixed",
      zIndex: "10",
      backgroundColor: "black",
      opacity: "0.7"
    }}>
      <div className="loading-spinner">
      </div>
    </div>
  );
}