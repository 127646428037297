import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import AppStoreButton from 'react-appstore-button';

// core components

function Download() {
  return (
    <>
      <div
      className="section section-download" data-background-color="black" id="download-section"
      >
        <Container>
          <Col className="text-center" lg="8" md="12">
            <h7>Download the LYZT.</h7>
            <AppStoreButton
              url="https://itunes.apple.com/us/app/my-nbn/id1247260936?mt=8"
            ></AppStoreButton>
            
            
            
          </Col>
          



          
          
        </Container>
      </div>
    </>
  );
}

export default Download;
