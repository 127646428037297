import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Card, CardHeader, Container, Row } from "reactstrap";

// core components

function About() {
  const [aboutDisplay, setAboutDisplay] = React.useState("flex");
  const [showOthers, setShowOthers] = React.useState("none");

  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
    if (window.innerWidth < 420) {
      setAboutDisplay("block");
      //console.log(aboutDisplay)
      setShowOthers("none");
    } else {
      setAboutDisplay("flex");
      // console.log(aboutDisplay)
      setShowOthers("none");
      //console.log(showOthers)
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  React.useEffect(() => {
    setDimension();
  }, []);

  return (
    <>
      <div
        className="section section-about"
        data-background-color="black"
        id="about-section"
        style={{
          backgroundImage:
            "url(" + require("assets/img/freeline.jpeg").default + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "700px",
        }}
      >
        <div className="space-50"></div>
        <Container className="text-center">
          <Card className="card-about" style={{ padding: "50px" }}>
            <CardHeader>
              <h1 style={{ fontWeight: "800" }}>ABOUT US</h1>
              <h3>
                Standing in line sucks. Everyone knows that. <br />
                <br />
                You didn't put on your best clothes, unfomfortable shoes, and
                ask that special person out on a date just to stand on a
                sidewalk, listening to others have the good night you planned
                for and deserve.
                <br />
                <br />
                You can keep standing there... or you can get on
                <br />
                <strong>The LYZT.</strong>
                <br />
                <br />
                <Row style={{ display: aboutDisplay }}>
                  <div className="col">
                    <img
                      src={require("assets/img/SS1.png").default}
                      style={{ maxHeight: "500px" }}
                    />
                    <br />
                    Find a Venue.
                    <br />
                    <br />
                  </div>
                  <div className="col">
                    <img
                      src={require("assets/img/SS2.png").default}
                      style={{ maxHeight: "500px" }}
                    />
                    <br />
                    Buy a Pass.
                    <br />
                    <br />
                  </div>
                  <div className="col">
                    <img
                      src={require("assets/img/feature19.png").default}
                      style={{ maxHeight: "500px" }}
                    />
                    <br />
                    Enjoy.
                    <br />
                  </div>
                </Row>
                <br />
                Now if only we could do something about the shoes...
              </h3>
            </CardHeader>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default About;
