import React, { useEffect } from "react";
import { Input, Button, Card, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { useState } from "react";
import { PictureAsPdfSharp } from "@mui/icons-material";
import { db } from "firebase/firebase.utils";

export function ScrollImage(props) {
    const { imageUrl, fullArray, venueID } = props;

    var imageArray = props.fullArray
    var image = props.imageUrl
    
    const [url, setURL] = React.useState("")
    const [urlValidMessage, setUrlValidMessage] = React.useState("")
    const [isActive, setIsActive] = React.useState(true);
    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);

    var arraySize = props.fullArray.length

const handlePicURLChange = event => {
    var validUrl = new RegExp( /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);
    setURL(event.target.value)
    if (!validUrl.test(event.target.value)){
        setUrlValidMessage("This is not a valid URL")
    } else {setUrlValidMessage("")}
}

const savePhoto = event => {
    
    
    var target = imageArray.indexOf(image)
    imageArray[target] = url
    
    const venueLocation = db.collection("venues").doc(venueID)
          venueLocation.update({
            venueImages: imageArray
        }, { merge: true })
        setUrlValidMessage("-Update Saved-")
        setTimeout(function(){
            setUrlValidMessage("");
        }, 1000);
}

const removePhoto = event => {
    var target = imageArray.indexOf(image)
    imageArray.splice(target, 1)
    setIsActive(false)
    const venueLocation = db.collection("venues").doc(venueID)
          venueLocation.update({
            venueImages: imageArray
        }, { merge: true })
}

React.useEffect(() => {
    setURL(props.imageUrl)
    
  }, [])

if (!isActive) {return false}

return (
    <>
    <Card style={{padding:"15px", borderRadius:"20px"}}>
        <img src={url} style={{maxHeight:"20vh", borderRadius:"10px"}} ></img>
            <InputGroup
                className={
                "no-border input-lg" +
                (firstFocus ? " input-group-focus" : "")
                }
                style={{marginTop:"10px"}}
            >
                <InputGroupAddon addonType="prepend">
                <InputGroupText>
                    <i className="now-ui-icons objects_globe"></i>
                </InputGroupText>
                </InputGroupAddon>
                <Input
                
                type="url"
                onChange={handlePicURLChange}
                onFocus={() => setFirstFocus(true)}
                onBlur={() => setFirstFocus(false)}
                value = {url}
                ></Input>
            </InputGroup>
        <p>{urlValidMessage}</p>
        <Button onClick={savePhoto} block>Save Updates to Scroll Photos</Button><Button onClick={removePhoto} block>Remove This Photo</Button>
    </Card>
    </>
  )

}