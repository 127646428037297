import React, { useEffect } from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useHistory } from "react-router";
import { db } from "firebase/firebase.utils";
import { app } from "firebase/firebase.utils";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Switch from "react-bootstrap-switch";
import { Alert } from "reactstrap";
import { functions } from "firebase/firebase.utils";
import { getFunctions, httpsCallable, setData } from "firebase/functions";
import LoadingSpinner from "components/subcomponent/LoadingSpinner";
import CustomPaginationActionsTable from "views/examples/TableTest";
import CollapsibleTable from "./LedgerTable";
import { storage } from "firebase/firebase.utils";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  uploadBytes,
} from "firebase/storage";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
} from "reactstrap";

// core components
import DefaultFooter from "components/Footers/DefaultFooter.js";
import VenueNavbar from "components/Navbars/VenueNavbar";
import VenueHeader from "components/Headers/VenueHeader";
import { stepButtonClasses } from "@mui/material";
import { ScrollImage } from "components/subcomponent/ScrollImage";
import { BouncerElement } from "components/subcomponent/BouncerElement";

function VenuePortal() {
  const [pills, setPills] = React.useState("1");
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [appUser, setAppUser] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [venueName, setVenueName] = React.useState("Venue");
  const [venueAddress, setVenueAddress] = React.useState("");
  const [venueCity, setVenueCity] = React.useState("");
  const [venueZip, setVenueZip] = React.useState("");
  const [web, setWeb] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [venueID, setVenueID] = React.useState("");
  const [testPass, setTestPass] = React.useState(20);
  const [passPrice, setPassPrice] = React.useState(0);
  const [reservePass, setReservePass] = React.useState(20);
  const [automation, setAutomation] = React.useState(true);
  const [alert1, setAlert1] = React.useState(false);
  const [alertType, setAlertType] = React.useState("success");
  const [alertMessage, setAlertMessage] = React.useState("Alert!");
  const [dataValid, setDataValid] = React.useState(false);
  const [phoneValid, setPhoneValid] = React.useState(true);
  const [zipValid, setZipValid] = React.useState(true);
  const [urlValid, setUrlValid] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [abalance, setABalance] = React.useState(0);
  const [pbalance, setPBalance] = React.useState(0);
  const [isLoadingLite, setIsLoadingLite] = React.useState(false);
  const [imageTest, setImageTest] = React.useState();
  const [uploadImage, setUploadImage] = React.useState();
  const [venueImageArray, setVenueImageArray] = React.useState(Array());
  const [bouncersearchEmail, setBouncerSearchEmail] = React.useState();
  const [bouncerWarning, setBouncerWarning] = React.useState();
  const [bouncerExists, setBouncerExists] = React.useState(false);
  const [bouncerPic, setBouncerPic] = React.useState("");
  const [bouncerName, setBouncerName] = React.useState("");
  const [bouncerEmail, setBouncerEmail] = React.useState("");
  const [bouncerUID, setBouncerUID] = React.useState("");
  const [bouncerObject, setBouncerObject] = React.useState();
  const [venueBouncerArray, setVenueBouncerArray] = React.useState(Array());

  var valid = true;
  const auth = getAuth();
  let history = useHistory();
  var storageRef = ref(storage, "Venue/DdBluhNta5RxFMTDibBM.jpeg");
  var arrayImageNumber = venueImageArray.length;

  var bouncerArray = Array();

  //Check Auth
  React.useEffect(() => {
    //populateBouncers()
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        history.push("/login-page");
      } else {
        const uid = user.uid;
        var docRef = db.collection("users").doc(uid);
        docRef.get().then((doc) => {
          if (doc.exists) {
            var userData = doc.data();
            const M = userData.M;
            const onBoard = userData.onBoard;
            //console.log(onBoard)
            if (onBoard == true && M == "") {
              history.push("/onboard");
            } else if (M != "" && onBoard == true) {
              history.push("/onboardcompletion");
            } else {
              //
            }
          } else {
            signOut(auth);
          }
        });
      }
      setUser(user);
      populateForm();
      populatePass();
      populateBouncers();
      populateImages();
    });

    return unsubscribe;
  }, []);

  // Pass Control Functions
  const toggleSwitch = () => {
    var newAutomation = !automation;
    setAutomation(newAutomation);
    //console.log(newAutomation);
  };
  const testPassUp = () => {
    if (testPass <= 19) {
      var newPass = testPass + 1;
      setTestPass(newPass);
    }
  };
  const testPassDown = () => {
    if (testPass >= 1) {
      var newPass = testPass - 1;
      setTestPass(newPass);
    }
  };
  const pricePassUp = () => {
    if (passPrice <= 10000) {
      var newPass = passPrice + 5;
      setPassPrice(newPass);
    }
  };
  const pricePassDown = () => {
    if (passPrice >= 5) {
      var newPass = passPrice - 5;
      setPassPrice(newPass);
    }
  };
  const reservePassUp = () => {
    if (reservePass <= 19) {
      var newPass = reservePass + 1;
      setReservePass(newPass);
    }
  };
  const reservePassDown = () => {
    if (reservePass >= 1) {
      var newPass = reservePass - 1;
      setReservePass(newPass);
    }
  };
  const updatePass = async (event) => {
    event.preventDefault();
    //console.log("submitting")
    const newVenue = db.collection("venues").doc(venueID);
    newVenue
      .update(
        {
          activePasses: testPass,
          passResetAmount: reservePass,
          passResetAutomation: automation,
          venuePrice: passPrice * 100,
        },
        { merge: true }
      )
      //history.push('/index')
      .then(() => {
        //console.log(`Document ${venueID} successfully written!`);
        setAlertMessage("Passes Updated.");
        setAlert1(true);
        setAlertType("success");
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setAlertMessage("Error writing document: ", error);
        setAlert1(true);
        setAlertType("danger");
        window.scrollTo(0, 0);
      });
  };

  //Profile Field Handlers
  const handleVenNameChange = (event) => {
    setVenueName(event.target.value);
    if (event.target.value == "") {
      valid = false;
    } else {
      valid = true;
    }
  };
  const handleVenAddressChange = (event) => {
    setVenueAddress(event.target.value);
    if (event.target.value == "") {
      valid = false;
    } else {
      valid = true;
    }
  };
  const handleVenCityChange = (event) => {
    setVenueCity(event.target.value);
    if (event.target.value == "") {
      valid = false;
    } else {
      valid = true;
    }
  };
  const handleVenZipChange = (event) => {
    var validZip = new RegExp(/\d{5}/);
    setVenueZip(event.target.value);
    setZipValid(validZip.test(event.target.value));
    //console.log(validZip.test(event.target.value))
    //setDataValid(validZip.test(event.target.value))
  };
  const handlewebChange = (event) => {
    var validUrl = new RegExp(
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    );
    setWeb(event.target.value);
    setUrlValid(validUrl.test(event.target.value));
    //console.log(validUrl.test(event.target.value))
    setDataValid(validUrl.test(event.target.value));
  };
  const handlePhoneChange = (event) => {
    var validPhone = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
    setPhone(event.target.value);
    setPhoneValid(validPhone.test(event.target.value));
    //console.log(validPhone.test(event.target.value))
    setDataValid(validPhone.test(event.target.value));
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const formValid = (event) => {
    valid = false;
    //console.log("DataValid: " + dataValid)
    if (
      venueName != "" &&
      zipValid &&
      phoneValid &&
      urlValid &&
      venueAddress != "" &&
      venueCity != ""
    ) {
      valid = true;
    } else if (venueName == "") {
      setAlertMessage("The Venue Name Cannot be blank");
      setAlert1(true);
      setAlertType("danger");
      setDataValid(false);
    } else if (!zipValid) {
      setAlertMessage("The zip code you provided is invalid");
      setAlert1(true);
      setAlertType("danger");
      setDataValid(false);
    } else if (!phoneValid) {
      setAlertMessage("The phone number you provided is invalid");
      setAlert1(true);
      setAlertType("danger");
      setDataValid(false);
    } else if (!urlValid) {
      setAlertMessage("The URL you provided is invalid");
      setAlert1(true);
      setAlertType("danger");
      setDataValid(false);
    } else if (venueCity == "") {
      setAlertMessage("The Venue City Cannot be blank");
      setAlert1(true);
      setAlertType("danger");
      setDataValid(false);
    } else if (venueAddress == "") {
      setAlertMessage("The Venue Address Cannot be blank");
      setAlert1(true);
      setAlertType("danger");
      setDataValid(false);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setDataValid(false);
    //console.log("Set to False: Data: " + dataValid + " and Zip: " + zipValid)
    formValid();
    //console.log("Function Run: Data: " + dataValid + " and Zip: " + zipValid)
    if (!valid) {
      setAlert1(true);
      window.scrollTo(0, 0);
      return;
    }
    //console.log("Data: " + dataValid + "and Zip: " + zipValid)
    const newVenue = db.collection("venues").doc(venueID);
    newVenue
      .update(
        {
          venueAddress: venueAddress,
          venueCity: venueCity,
          venueDescription: description,
          venueID: venueID,
          venueName: venueName,
          venuePhone: phone,
          venueWeb: web,
          venueZip: venueZip,
        },
        { merge: true }
      )
      //history.push('/index')

      .then(() => {
        //console.log(`Document ${venueID} successfully written!`);
        //history.push()
        setAlertMessage("You have successfully updated the Venue Information");
        setAlert1(true);
        setAlertType("success");
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        console.error("Error writing document: ", error);
        setAlertMessage("Error writing document: ", error);
        setAlert1(true);
        setAlertType("danger");
      });
  };

  //Photo Functions
  const getPicture = () => {
    getDownloadURL(storageRef).then((url) => {
      setImageTest(url);
      //console.log(url)
    });
  };
  const savePhoto = () => {
    const storageRef = ref(storage, "Venue/" + venueID + ".jpeg");
    //console.log("saving...")
    setIsLoading(true);
    if (uploadImage != null) {
      uploadBytes(storageRef, uploadImage).then((snapshot) => {
        //console.log('Uploaded a blob or file!');
        setIsLoading(false);
        setAlert1(true);
        setAlertMessage("Photo Uploaded Successfully");
        setAlertType("success");
        window.scrollTo(0, 0);
      });
    } else {
      setIsLoading(false);
      setAlert1(true);
      setAlertMessage("You must select a photo to upload.");
      setAlertType("danger");
      window.scrollTo(0, 0);
    }
  };
  const uploadPhoto = (file) => {
    // Ensure file is an image
    if (file) {
      let allowedImageTypes = ["image/jpeg", "image/gif", "image/png"];
      if (!allowedImageTypes.includes(file.type)) {
        //console.log("Allowed file type's are: [ .jpg .png .gif ]");
        setAlert1(true);
        setAlertType("danger");
        setAlertMessage(
          "File must be an image in .jpg, .jpeg, .png, or .gif format"
        );
        window.scrollTo(0, 0);
        return false;
      } else {
        //Prep Image for Save and Show thumbnail
        setImageTest(URL.createObjectURL(file));
        setUploadImage(file);
      }
    }
  };
  const cancelPhotoUpdate = () => {
    getDownloadURL(storageRef).then((url) => {
      setImageTest(url);
    });
  };
  const addScrollImage = () => {
    if (arrayImageNumber < 5) {
      arrayImageNumber++;
      var arrayAdder = [...venueImageArray];
      arrayAdder.push("http://thelyzt.com/static/media/clubbg.e197e7a5.jpg");
      setVenueImageArray(arrayAdder);
    }
  };

  //Bouncer Functions
  const handleBouncerEmailChange = (event) => {
    setBouncerSearchEmail(event.target.value);
  };
  const searchForBouncer = async (event) => {
    //populateBouncers()
    db.collection("users")
      .where("email", "==", bouncersearchEmail)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.exists) {
          setBouncerWarning(
            "There is no Lyzt account associated with that email address."
          );
          setBouncerExists(false);
        }
        querySnapshot.forEach((doc) => {
          setBouncerWarning("");
          var data = doc.data();
          setBouncerExists(true);
          setBouncerName(data.firstName + " " + data.lastName);
          setBouncerEmail(data.email);
          setBouncerUID(data.id);
          setBouncerObject(data);
          //get pic url
          var bouncerStorageRef = ref(storage, "User/" + data.id + "_500x500");
          getDownloadURL(bouncerStorageRef)
            .then((url) => {
              setBouncerPic(url);
            })
            .catch((err) => {
              getDownloadURL(storageRef).then((url) => {
                setBouncerPic(url);
              });
            });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };
  const addBouncer = async (event) => {
    db.collection("users").doc(bouncerUID).update(
      {
        B: venueID,
      },
      { merge: true }
    );

    var addingBouncer = db
      .collection("venues")
      .doc(venueID)
      .collection("bouncers")
      .doc(bouncerUID);

    addingBouncer.set({
      bouncerUID: bouncerUID,
      email: bouncerEmail,
      name: bouncerName,
    });

    var newBouncerObject = {
      bouncerUID: bouncerUID,
      email: bouncerEmail,
      name: bouncerName,
    };

    bouncerArray = [...venueBouncerArray];
    bouncerArray.push(newBouncerObject);
    setVenueBouncerArray(bouncerArray);
    setBouncerExists(false);
  };

  //Ledger Functions
  const getConnectLink = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    var docRef = db.collection("users").doc(auth.currentUser.uid);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const M = doc.data().M;
          var venRef = db.collection("venues").doc(M);
          venRef.get().then((doc) => {
            if (doc.exists) {
              var venue = doc.data();
              const functions = getFunctions();
              const data = { account: venue.stripeConnectID };
              const accessAccount = httpsCallable(
                functions,
                "accessConnectAccount"
              );

              accessAccount(data).then((result) => {
                const data = result.data;
                //window.location.href = data.url;
                window.open(data.url, "_blank");
                setIsLoading(false);
              });
            } else {
              //console.log("no venue");
            }
          });
        } else {
          //console.log("Here maybe?" + user.uid)
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };
  //Pull Data from Stripe
  React.useEffect(() => {
    setIsLoadingLite(true);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUser(user);
        //console.log(uid)
        var docRef = db.collection("users").doc(uid);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              //console.log(doc.data())
              const onBoard = doc.data().onBoard;
              const M = doc.data().M;
              var venRef = db.collection("venues").doc(M);
              venRef.get().then((doc) => {
                if (doc.exists) {
                  var venue = doc.data();
                  setVenueName(venue.venueName);
                  const functions = getFunctions();
                  const data = { account: venue.stripeConnectID };
                  const accessBalance = httpsCallable(
                    functions,
                    "accessConnectBalance"
                  );

                  accessBalance(data).then((result) => {
                    const data = result.data;
                    //console.log(data)
                    setABalance(data.available[0].amount);
                    setPBalance(data.pending[0].amount);
                  });
                  setIsLoadingLite(false);
                } else {
                  console.log("no venue");
                }
              }); //end of doc func

              if (onBoard == true || M == "") {
                history.push("/onboard");
              }
            } else {
              signOut(auth);
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });

        //history.push("/user")
        // ...
      } else {
        // User is signed out
        // ...
      }
    });

    return unsubscribe;
  }, []);

  //Data Population
  const populatePass = () => {
    //console.log("populating pass...")
    var docRef = db.collection("users").doc(auth.currentUser.uid);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setAppUser(doc.data());
          const M = doc.data().M;
          var venRef = db.collection("venues").doc(M);
          venRef.get().then((doc) => {
            if (doc.exists) {
              var venue = doc.data();
              setReservePass(venue.passResetAmount);
              setPassPrice(venue.venuePrice / 100);
              setTestPass(venue.activePasses);
              setVenueID(venue.venueID);
              setAutomation(venue.passResetAutomation);
            } else {
              //console.log("no venue");
            }
          });
        } else {
          //console.log("Here maybe?" + user.uid);
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };
  const populateForm = () => {
    //console.log("populating...")
    var docRef = db.collection("users").doc(auth.currentUser.uid);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setAppUser(doc.data());
          const M = doc.data().M;
          var venRef = db.collection("venues").doc(M);
          venRef.get().then((doc) => {
            if (doc.exists) {
              var venue = doc.data();
              setVenueAddress(venue.venueAddress);
              setVenueCity(venue.venueCity);
              setVenueName(venue.venueName);
              setVenueZip(venue.venueZip);
              setPhone(venue.venuePhone);
              setWeb(venue.venueWeb);
              setDescription(venue.venueDescription);
              setVenueID(venue.venueID);
            } else {
              //console.log("no venue");
            }
          });
        } else {
          //console.log("Here maybe?" + user.uid);
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };
  const populateImages = () => {
    //console.log("populating...")
    var docRef = db.collection("users").doc(auth.currentUser.uid);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const M = doc.data().M;
          var venRef = db.collection("venues").doc(M);
          venRef.get().then((doc) => {
            if (doc.exists) {
              var venue = doc.data();
              setVenueImageArray(venue.venueImages);
              setVenueID(venue.venueID);
            } else {
              //console.log("no venue");
            }
          });
        } else {
          //console.log("Here maybe?" + user.uid);
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };
  const populateBouncers = () => {
    //console.log("populating...")
    //setVenueBouncerArray(Array())
    var docRef = db.collection("users").doc(auth.currentUser.uid);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const M = doc.data().M;
          setVenueID(M);
          var venRef = db.collection("venues").doc(M).collection("bouncers");
          venRef
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                //bouncerArray = [...venueBouncerArray]
                bouncerArray.push(doc.data());
                //setVenueBouncerArray(bouncerArray)
                //console.log(doc.data().email)
                //console.log(bouncerArray);
                setVenueBouncerArray(bouncerArray);
                //console.log(bouncerArray + doc.data().name)
              });
            })
            .then(setVenueBouncerArray(bouncerArray));
        } else {
          //
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  //On Run
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    getPicture();

    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      {isLoading ? <LoadingSpinner /> : <center></center>}
      <VenueNavbar />
      <div className="wrapper">
        <VenueHeader />
        <div className="section">
          <Container>
            <div
              className="button-container"
              style={{ boxSizing: "content-box" }}
            >
              <Nav
                className="nav-tabs"
                style={{ display: "inline-flex", justifyContent: "center" }}
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    className={pills === "1" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setPills("1");
                    }}
                  >
                    Ledger
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={pills === "2" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setPills("2");
                      populateForm();
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={pills === "3" ? "active" : ""}
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPills("3");
                      populatePass();
                    }}
                  >
                    Pass Control
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={pills === "4" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setPills("4");
                      //setVenueBouncerArray(Array())
                      //populateBouncers();
                    }}
                  >
                    Bouncers
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={pills === "5" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setPills("5");
                      getPicture();
                      populateImages();
                    }}
                  >
                    Photos
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <Alert color={alertType} isOpen={alert1}>
              <Container>
                {alertMessage}
                <button
                  type="button"
                  className="close"
                  onClick={() => setAlert1(false)}
                >
                  <span aria-hidden="true">
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </span>
                </button>
              </Container>
            </Alert>

            <Row style={{ display: "block" }}>
              <Col className="ml-auto mr-auto" md="6">
                <div className="nav-align-center"></div>
              </Col>
              <TabContent
                className="gallery"
                style={{ marginTop: "0px" }}
                activeTab={"pills" + pills}
              >
                <TabPane tabId="pills2">
                  <Card className="card-plain" style={{ width: "100%" }}>
                    <Form
                      onSubmit={handleSubmit}
                      action=""
                      className="form"
                      method=""
                    >
                      <CardHeader>
                        <center>
                          <h2>Update Venue Information</h2>
                        </center>
                      </CardHeader>
                      <CardBody>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (firstFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons objects_diamond"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Venue Name"
                            name="venueName"
                            type="text"
                            onChange={handleVenNameChange}
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                            value={venueName}
                          ></Input>
                        </InputGroup>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (lastFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons location_pin"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Street Address"
                            name="venueAddress"
                            type="text"
                            onChange={handleVenAddressChange}
                            onFocus={() => setLastFocus(true)}
                            onBlur={() => setLastFocus(false)}
                            value={venueAddress}
                          ></Input>
                          <Input
                            placeholder="City"
                            name="venueCity"
                            type="text"
                            onChange={handleVenCityChange}
                            onFocus={() => setLastFocus(true)}
                            onBlur={() => setLastFocus(false)}
                            value={venueCity}
                          ></Input>
                          <Input
                            placeholder="Zip Code"
                            name="venueZip"
                            type="text"
                            onChange={handleVenZipChange}
                            onFocus={() => setLastFocus(true)}
                            onBlur={() => setLastFocus(false)}
                            value={venueZip}
                          ></Input>
                        </InputGroup>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (lastFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons objects_globe"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Website Address"
                            name="web"
                            type="url"
                            onChange={handlewebChange}
                            onFocus={() => setLastFocus(true)}
                            onBlur={() => setLastFocus(false)}
                            value={web}
                          ></Input>
                        </InputGroup>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (lastFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons tech_mobile"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Phone Number"
                            name="phone"
                            type="tel"
                            onChange={handlePhoneChange}
                            onFocus={() => setLastFocus(true)}
                            onBlur={() => setLastFocus(false)}
                            value={phone}
                          ></Input>
                        </InputGroup>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (lastFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons text_align-center"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Venue Description"
                            name="description"
                            type="textarea"
                            onChange={handleDescriptionChange}
                            onFocus={() => setLastFocus(true)}
                            onBlur={() => setLastFocus(false)}
                            value={description}
                            style={{ minHeight: "200px" }}
                          ></Input>
                        </InputGroup>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button
                          block
                          className="btn"
                          color="black"
                          onClick={handleSubmit}
                          size="lg"
                        >
                          Update Venue Information
                        </Button>
                      </CardFooter>
                    </Form>
                  </Card>
                </TabPane>
                <TabPane tabId="pills4">
                  <center>
                    <h2 style={{ marginTop: "0px" }}>Bouncers</h2>
                  </center>
                  <Row>
                    <Col
                      className="ml-auto mr-auto"
                      md="6"
                      style={{ paddingLeft: "5vh", paddingRight: "5vh" }}
                    >
                      <center>
                        <h4 style={{ marginTop: "0px" }}>Add Bouncers</h4>
                      </center>
                      <p>
                        To add a bouncer to your venue, please type in the email
                        address associated with the person's Lyzt account. If
                        they have an account, you'll see their information
                        before confirming them.
                      </p>
                      <Card style={{ padding: "1.5em", borderRadius: "20px" }}>
                        <h4>Bouncer Search</h4>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (firstFocus ? " input-group-focus" : "")
                          }
                          style={{ marginTop: "10px" }}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_single-02"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            onChange={handleBouncerEmailChange}
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                            placeholder="Bouncer's Email"
                          ></Input>
                        </InputGroup>

                        <center>
                          <p>{bouncerWarning}</p>
                        </center>
                        <Button block onClick={searchForBouncer}>
                          Search
                        </Button>
                        <br />
                        <center>
                          <img
                            src={bouncerPic}
                            style={{
                              borderRadius: "50%",
                              height: "200px",
                              width: "200px",
                              objectFit: "cover",
                            }}
                            hidden={!bouncerExists}
                          />
                          <p hidden={!bouncerExists}>
                            {bouncerName}
                            <br />
                            {bouncerEmail}
                          </p>
                          <Button
                            onClick={addBouncer}
                            block
                            hidden={!bouncerExists}
                          >
                            Add Bouncer
                          </Button>
                        </center>
                      </Card>
                    </Col>

                    <Col
                      className="ml-auto mr-auto"
                      md="6"
                      style={{ paddingLeft: "5vh", paddingRight: "5vh" }}
                    >
                      <center>
                        <h4 style={{ marginTop: "0px" }}>Current Bouncers</h4>
                      </center>
                      <center>
                        {venueBouncerArray.map((data, index) => (
                          <BouncerElement
                            key={index}
                            bouncer={data}
                            fullArray={venueBouncerArray}
                            venueID={venueID}
                          />
                        ))}
                      </center>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="pills5">
                  <center>
                    <h2>Photos</h2>
                  </center>
                  <Row>
                    <Col
                      className="ml-auto mr-auto"
                      md="6"
                      style={{ paddingLeft: "5vh", paddingRight: "5vh" }}
                    >
                      <center>
                        <h4 style={{ marginTop: "0px" }}>
                          Venue Profile Photo
                        </h4>
                      </center>
                      <p>
                        The venue profile photo shows up when a customer is
                        searching for your venue and is also featured on the
                        face of each pass.
                      </p>
                      <img
                        src={imageTest}
                        alt="imageTest"
                        style={{
                          maxHeight: "1000vh",
                          marginRight: "10px",
                          borderRadius: "20px",
                        }}
                      />
                      <br />
                      <h4>Select A New Photo:</h4>

                      <h6>
                        <Input
                          type="file"
                          onChange={(e) => {
                            uploadPhoto(e.target.files[0]);
                          }}
                          accept="image/*"
                          style={{
                            backgroundColor: "",
                          }}
                        />
                      </h6>
                      <center>
                        <Button disabled={isLoading} onClick={savePhoto} block>
                          Save New Profile Photo
                        </Button>
                        <Button
                          onClick={cancelPhotoUpdate}
                          style={{ backgroundColor: "lightgray" }}
                          block
                        >
                          Cancel
                        </Button>
                      </center>
                    </Col>

                    <Col
                      className="ml-auto mr-auto"
                      md="6"
                      style={{ paddingLeft: "5vh", paddingRight: "5vh" }}
                    >
                      <center>
                        <h4 style={{ marginTop: "0px" }}>Scroll Photos</h4>
                      </center>
                      <p>
                        Scroll photos are extra photos you can include in the
                        venue's app profile. Input the url for the image. If the
                        url is correct, a thumbnail of the image will appear.
                      </p>
                      <center>
                        {venueImageArray.map((url, index) => (
                          <ScrollImage
                            key={url + index}
                            imageUrl={url}
                            fullArray={venueImageArray}
                            venueID={venueID}
                          />
                        ))}
                        {venueImageArray.length == 0 ? (
                          <ScrollImage
                            imageUrl=""
                            fullArray={venueImageArray}
                            venueID={venueID}
                          />
                        ) : (
                          <center></center>
                        )}

                        <Button onClick={addScrollImage}>
                          Add Another Image
                        </Button>
                      </center>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="pills1" style={{ paddingBottom: "0px" }}>
                  <Col
                    className="ml-auto mr-auto"
                    md="12"
                    style={{ paddingBottom: "0px" }}
                  >
                    <Row>
                      <Col className="ml-auto mr-auto" md="4">
                        <Card className="card-plain">
                          <center>
                            <h3>{venueName}'s Ledger</h3>
                          </center>
                        </Card>
                      </Col>
                      <Col className="ml-auto mr-auto" md="4">
                        <Card className="card-plain">
                          <center>
                            <p>Current Balance:</p>
                            {isLoadingLite ? (
                              <h2>
                                $
                                <img
                                  style={{ maxHeight: "30px" }}
                                  src={require("assets/img/spin.gif").default}
                                />{" "}
                              </h2>
                            ) : (
                              <h2>
                                ${(abalance / 100 + pbalance / 100).toFixed(2)}
                              </h2>
                            )}
                          </center>
                        </Card>
                      </Col>
                      <Col className="ml-auto mr-auto" md="4">
                        <Card className="card-plain">
                          <center>
                            ${(abalance / 100).toFixed(2)} available <br />$
                            {(pbalance / 100).toFixed(2)} pending
                            <br />
                            <Button
                              onClick={getConnectLink}
                              disabled={isLoading}
                            >
                              Stripe Dashboard
                            </Button>
                          </center>
                        </Card>
                      </Col>
                    </Row>
                    <Card>
                      <div>
                        <CollapsibleTable style={{ padding: "1.5em" }} />
                      </div>
                    </Card>{" "}
                  </Col>
                </TabPane>
                <TabPane tabId="pills3">
                  <Col className="ml-auto mr-auto" md="12">
                    <center>
                      <h2>Pass Control</h2>
                    </center>
                    <Row className="collections">
                      <Col md="6">
                        <center>
                          <Card
                            style={{ padding: "1.5em", borderRadius: "20px" }}
                          >
                            <center>
                              <h3>Active Passes:</h3>
                              <h1>{testPass}</h1>
                              <p>(Max: 20)</p>
                              <Button onClick={testPassDown}>-</Button>
                              <Button onClick={testPassUp}>+</Button>
                            </center>
                          </Card>

                          <Card
                            style={{ padding: "1.5em", borderRadius: "20px" }}
                          >
                            <h3>Pass Price:</h3>
                            <h1>${passPrice}</h1>
                            <p>(Max: $10,000)</p>
                            <Button onClick={pricePassDown}>-</Button>
                            <Button onClick={pricePassUp}>+</Button>
                          </Card>
                        </center>
                      </Col>
                      <Col md="6">
                        <Card
                          style={{ padding: "1.5em", borderRadius: "20px" }}
                        >
                          <center>
                            <h3>Automated Pass Refill:</h3>
                            <Switch
                              offColor=""
                              offText="Off"
                              onColor=""
                              name="Switch"
                              onText="On"
                              onChange={toggleSwitch}
                              value={automation}
                            ></Switch>
                            <p>
                              If this feature is activated, your venue's passes
                              will be refilled to the amount below each
                              afternoon at 12pm Eastern.
                            </p>

                            <h1>{reservePass}</h1>
                            <p>(Max: 20)</p>
                            <Button onClick={reservePassDown}>-</Button>
                            <Button onClick={reservePassUp}>+</Button>
                          </center>
                        </Card>
                      </Col>
                    </Row>
                    <Button block onClick={updatePass}>
                      Save Changes
                    </Button>
                  </Col>
                </TabPane>
              </TabContent>
            </Row>
          </Container>
        </div>
        <DefaultFooter style={{ marginTop: "4000px" }} />
      </div>
    </>
  );
}

export default VenuePortal;
